import React from 'react';
import { Hero, Partners, About, Aid, References } from '../components';
import Cta from '../components/home/cta';
import Layout from '../components/layout';

const IndexPage = () => (
  <Layout
    seo={{
      title: 'Főoldal',
      description:
        'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
    }}
  >
    <Hero />
    <About />
    <Cta />
    <Aid />
    <Partners />
    <References />
  </Layout>
);

export default IndexPage;
