import React from 'react';
import CtaCard from '../layout/cta-card';

const Cta = () => {
  return (
    <div className="container md:flex md:flex-row py-20">
      <CtaCard
        title="Célcsoportnak"
        color="bgfirst"
        img="person"
        description="A Fortély-sátor Alapítvány célcsoportja a 10-18 éves, szociálisan hátrányos helyzetű fiatalok csoportja, elsősorban azok, akik már részt vesznek valamilyen tanodaprogramban."
        buttonText="TOVÁBB A CÉLCSOPORTRA"
        href="/celcsoportnak"
      />
      <CtaCard
        title="Vállalatoknak"
        color="bgsecond"
        img="building"
        description="Sikerélményt viszünk olyan szervezetek életébe is, amelyek a szabadulószobán keresztül szeretnének nem hétköznapi élményt adni a kollégáknak, partnereknek."
        buttonText="TOVÁBB A VÁLLALATOKRA"
        href="/vallalatoknak"
      />
    </div>
  );
};

export default Cta;
